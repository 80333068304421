<template>
  <v-app>
    <div class="row justify-content-center">
      <div class="col-12">
        <div class="card card-custom">
          <div class="card-body p-0">
            <!--begin: Wizard-->
            <div
              class="wizard wizard-1"
              id="kt_wizard_v1"
              data-wizard-state="step-first"
              data-wizard-clickable="true"
            >
              <!--begin: Wizard Body-->
              <div
                class="row justify-content-center my-10 px-8 my-lg-10 px-lg-8"
              >
                <div class="col-xl-12">
                  <!--begin: Wizard Form-->
                  <form class="form" @submit.stop.prevent="formOnsubmit()">
                    <!--begin: Wizard Step 1-->
                    <div
                      class="pb-5"
                      data-wizard-type="step-content"
                      data-wizard-state="current"
                    >
                      <h3
                        class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg"
                      >
                        Tambah Jenis Absen
                      </h3>
                      <b-form-group
                        id="input-group-name"
                        label="Nama:"
                        label-for="input-name"
                      >
                        <b-form-input
                          id="input-name"
                          v-model="form.name"
                          placeholder="Nama Jenis Absen"
                        ></b-form-input>
                        <small class="text-danger">{{ error.name }}</small>
                      </b-form-group>

                      <b-form-group
                        id="input-group-open-time"
                        label="Mulai Waktu Absen:"
                        label-for="input-open-time"
                      >
                        <v-dialog
                          ref="dialog1"
                          v-model="modal2"
                          :return-value.sync="form.open_time"
                          persistent
                          width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <b-form-input
                              type="text"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                              v-model="form.open_time"
                            ></b-form-input>
                          </template>
                          <v-time-picker
                            v-if="modal2"
                            v-model="form.open_time"
                            full-width
                            use-seconds
                            scrollable
                            format="24hr"
                          >
                            <v-spacer></v-spacer>
                            <v-btn text color="primary" @click="modal2 = false">
                              BATAL
                            </v-btn>
                            <v-btn
                              text
                              color="primary"
                              @click="$refs.dialog1.save(form.open_time)"
                            >
                              SIMPAN
                            </v-btn>
                          </v-time-picker>
                        </v-dialog>
                        <small class="text-danger">{{ error.open_time }}</small>
                      </b-form-group>

                      <b-form-group
                        id="input-group-name"
                        label="Berakhir Waktu Absen:"
                        label-for="input-name"
                      >
                        <v-dialog
                          ref="dialog"
                          v-model="modal3"
                          :return-value.sync="form.close_time"
                          persistent
                          width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <b-form-input
                              type="text"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                              v-model="form.close_time"
                            ></b-form-input>
                          </template>
                          <v-time-picker
                            v-if="modal3"
                            v-model="form.close_time"
                            full-width
                            use-seconds
                            scrollable
                            format="24hr"
                          >
                            <v-spacer></v-spacer>
                            <v-btn text color="primary" @click="modal3 = false">
                              BATAL
                            </v-btn>
                            <v-btn
                              text
                              color="primary"
                              @click="$refs.dialog.save(form.close_time)"
                            >
                              SIMPAN
                            </v-btn>
                          </v-time-picker>
                        </v-dialog>
                        <small class="text-danger">{{
                          error.close_time
                        }}</small>
                      </b-form-group>

                      <!-- Description input -->
                      <b-form-group id="input-group-description">
                        <label for="input-description"
                          >Deskripsi:
                          <em class="text-muted">opsional</em></label
                        >
                        <b-form-textarea
                          id="input-description"
                          v-model="form.description"
                          placeholder="Deskripsi Jenis Absen"
                          rows="4"
                          max-rows="8"
                        ></b-form-textarea>
                        <small class="text-danger">{{
                          error.description
                        }}</small>
                      </b-form-group>
                    </div>
                    <!--end: Wizard Step 1-->

                    <!--begin: Wizard Actions -->
                    <div
                      class="d-flex justify-content-between border-top pt-10"
                    >
                      <div>
                        <b-button type="submit" variant="primary"
                        >Simpan</b-button
                      >
                      <b-button
                        type="button"
                        class="ml-2"
                        variant="default"
                        @click="$router.push('/masters/presence-types')"
                      >
                        Batal
                      </b-button>
                      </div>
                    </div>
                    <!--end: Wizard Actions -->
                  </form>
                  <!--end: Wizard Form-->
                </div>
              </div>
              <!--end: Wizard Body-->
            </div>
          </div>
          <!--end: Wizard-->
        </div>
      </div>
    </div>
  </v-app>
</template>

<style scoped>
.image-input-wrapper {
  width: 250px !important;
  height: 250px !important;
}

.image-input-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
</style>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import module from "@/core/modules/CrudModule.js";
import validation from "@/core/modules/ValidationModule.js";
import Swal from "sweetalert2";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";

export default {
  data() {
    return {
      form: {
        name: "",
        description: "",
        open_time: "01:00:00",
        close_time: "17:00:00",
      },
      error: {
        name: "",
        description: "",
        open_time: "",
        close_time: "",
      },
      modal2: false,
      modal3: false,
    };
  },
  methods: {
    async formOnsubmit() {
      // Make Request
      let response = await module.submit(this.form, "api/presence-types")
      // Check Response
      if (response.state == 'error') {
        // Validation Error
        this.error = validation.setValidationError(this.error, response.error.errors)
      } else {
        // Success
        Swal.fire(response.success.title, response.success.message, 'success')
        this.$router.push('/masters/presence-types')
      }
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Master", route: "/masters/settings" },
      { title: "Jenis Absen", route: "/masters/presence-types" },
      { title: "Tambah Jenis Absen" },
    ]);
  },
};
</script>