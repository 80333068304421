import { render, staticRenderFns } from "./PresenceTypesAdd.vue?vue&type=template&id=4ca57cd5&scoped=true&"
import script from "./PresenceTypesAdd.vue?vue&type=script&lang=js&"
export * from "./PresenceTypesAdd.vue?vue&type=script&lang=js&"
import style0 from "./PresenceTypesAdd.vue?vue&type=style&index=0&id=4ca57cd5&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ca57cd5",
  null
  
)

export default component.exports